._{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 10px 40px 10px;
}
.block{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: calc(50% - 5px);
    justify-content: flex-end;
    background: var(--tg-theme-bg-color);
    border-radius: 8px;
    overflow: hidden;
}
.block2{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    background: aqua;
    background: var(--tg-theme-bg-color);
    border-radius: 8px;
    padding: 0 0 10px 0;
}
.imageBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}
.image{
    width: 100%;
}
.name{
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 138.462% */
    color: var(--tg-theme-text-color);
    margin: 0 5px;
}
.price{
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
}