._{
    padding: 40px 16px;
}
.logoBlock{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo{
    width: 100%;
}
.item{
    display: flex;
    padding: 9px 14px 9px 16px;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    justify-content: space-between;
}
.block_1{
    border-top: 1px solid var(--tg-theme-secondary-bg-color);
}
.text{
    color: var(--tg-theme-text-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    width: 100%;
}
.block{
    background: var(--tg-theme-bg-color);
    border-radius: 8px;
}