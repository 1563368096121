BODY{
    margin: 0;
    background: #F1F1F1;
    background: var(--tg-theme-secondary-bg-color);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Apple Color Emoji", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input:focus, textarea:focus, select:focus {
    outline: none !important;
}

.slick-list {
    height: 100%;
}
.slick-slide{
    height: 100%;
}
.slick-track{
    height: 100%;
}
.slick-slide > div {
    height: 100%;
}
.slick-next:before, .slick-prev:before {
    color: var(--tg-theme-button-color);
}
.slick-dots {
    bottom: -19px;
}
.slick-dots li.slick-active button:before {
    color: var(--tg-theme-button-color);
}
.slick-dots li button:before {
    color: var(--tg-theme-button-color);
}