.block{
    background-color: var(--tg-theme-secondary-bg-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: all .3s;
}
.imageBlock{
    width: 100%;
    height: 100%;
    background-color: var(--tg-theme-bg-color);
    border-radius: 10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.header{
    display: flex;
    background-color: var(--tg-theme-bg-color);
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    height: 50px;
}
.imageSquare{
    width: calc(100% - 20px);
    margin: auto;
    background-color: var(--tg-theme-secondary-bg-color);
    overflow: hidden;
    margin-top: 10px;
//margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imageSquare:before {
    content: "";
    padding-top: 100%;
    float: left;
}
.description{
    margin: 10px;
    overflow: auto;
    width: calc(100% - 20px);
    height: 50%;
    resize: none;
    border: none;
    font-size: 10pt;
    background-color: var(--tg-theme-bg-color);
    padding: 10px;
    border-radius: 5px;
}
.slider{
    width: calc(100% - 50px);
    height: 50%;
    margin-top: 5px;
    margin-bottom: 20px;
}
.sliderBlock{
    height: 100%;
}
.imageBlock2{
    height: 100%;
}
.name{
    text-align: left;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 138.462% */
    color: var(--tg-theme-text-color);
    margin: 0 5px;
}
.price{
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 141.176% */
    color: var(--tg-theme-hint-color);
}
.infoBlock{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    margin-bottom: 10px;
}